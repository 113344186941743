/* import __COLOCATED_TEMPLATE__ from './page-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { getLocale } from 'embercom/lib/locale';

export default Component.extend({
  classNames: ['start flex-auto flex flex-col overflow-auto bg-base-module'],
  classNameBindings: ['isJapanese:locale__ja'],

  redirectUrl: null,
  isDeveloper: false,

  urlParamsForSignIn: computed('redirectUrl', 'isDeveloper', function () {
    let params = [];

    if (this.redirectUrl) {
      params.push(`redirect_url=${this.redirectUrl}`);
    }

    if (this.isDeveloper) {
      params.push('developer=true');
    }

    if (params.length === 0) {
      return;
    } else {
      return `?${params.join('&')}`;
    }
  }),

  init() {
    this._super();
    this.set('isJapanese', getLocale() === 'ja');
  },
});
