/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { surveyFormats } from 'embercom/models/data/survey/constants';
import { isEmpty } from '@ember/utils';
import {
  INLINE_CONTROL_ALIGNMENT,
  INLINE_CONTROL_BLOCK_TYPES,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  singleBlockMode = true;
  allowedInline = ['bold', 'italic', 'anchor'];
  allowTextAlignment = false;
  allowImplicitMarginParagraphs = true;
  tools = [
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT, INLINE_CONTROL_BLOCK_TYPES];

  constructor(app, placeholder) {
    super(app);

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

export default class QuestionEditorComponent extends Component {
  @tracked blocksDoc;
  @service appService;
  @service store;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.question.serializedBlocks);
  }

  get question() {
    return this.args.question;
  }

  get composerConfig() {
    return new ComposerConfig(this.appService.app, this.placeholder);
  }

  get placeholder() {
    return this.shouldShowValidations ? 'Missing question title' : "What's your question?";
  }

  get isActiveQuestion() {
    if (this.args.deviceType === 'web') {
      return this.args.index === this.args.activeQuestionIndex;
    } else {
      return false;
    }
  }

  get borderClasses() {
    if (this.isActiveQuestion) {
      return 'border-opacity-1 border-2 question-editor-selected-neg-margin';
    } else if (this.isQuestionHovered) {
      return 'border-opacity-1 border -m-px';
    } else {
      return 'border-opacity-0';
    }
  }

  get isQuestionHovered() {
    if (!this.args.isEditing) {
      return false;
    }

    if (this.args.deviceType === 'web') {
      return this.args.index === this.args.currentHoverIndex;
    } else {
      return false;
    }
  }

  get isBannerFormat() {
    return this.args.surveyFormat === surveyFormats.banner;
  }

  get isPostFormat() {
    return this.args.surveyFormat === surveyFormats.post;
  }

  get shouldShowValidations() {
    return this.args.shouldShowValidations;
  }

  get requiredQuestionFormat() {
    if (!isEmpty(this.question.questionTitle) && this.question.data.required) {
      let format = 'survey__question-editor-required-question';

      if (this.isBannerFormat) {
        format += ' o__small';
      }

      return format;
    }

    return '';
  }

  get canDeleteQuestion() {
    if (this.isQuestionHovered) {
      return true;
    } else {
      return this.args.isEditing && this.isActiveQuestion;
    }
  }

  get backgroundContrastDependentColors() {
    if (this.isBannerFormat && this.args.isSurveyBackgroundDark) {
      return {
        borderColor: 'border-neutral-border',
        iconType: 'primary',
      };
    }

    return {
      borderColor: 'border-neutral-border',
      iconType: 'secondary',
    };
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.question.blocks = blockFragments;
  }
}
