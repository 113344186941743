/* import __COLOCATED_TEMPLATE__ from './early-stage-checkout.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type { AfterPaymentMethodSuccess } from 'embercom/components/stripe/stripe-component';
import { INTENT_TYPES } from 'embercom/components/stripe/stripe-component';
import { post } from 'embercom/lib/ajax';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  findSolution,
  percentDiscountForSolution,
} from 'embercom/lib/billing';
import { PricingModelIdentifier } from 'embercom/lib/billing/pricing-models';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Plan from 'embercom/models/plan';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type QuoteService from 'embercom/services/quote-service';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
import moment from 'moment-timezone';
import _ from 'underscore';

interface Args {
  solutionId: number;
}
interface Signature {
  Element: any;
  Args: Args;
}

// https://www.intercom.com/help/en/articles/9361928-early-stage-program-from-june-27-2024
const EARLY_STAGE_WITH_COPILOT_PRICING_ARTICLE_ID = 9361928;
// https://www.intercom.com/help/en/articles/9361933-startup-partner-program-from-june-27-2024
const EARLY_STAGE_FREE_WITH_COPILOT_PRICING_ARTICLE_ID = 9361933;

export default class EarlyStageCheckout extends Component<Signature> {
  @service declare appService: any;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare earlyStageService: any;
  @service declare router: Router;
  @service declare store: any;

  @service declare purchaseAnalyticsService: any;

  @tracked billingPeriod = BILLING_PERIODS.Monthly;
  @tracked selectedSeatNumber = 1;
  @tracked minimumSeatNumber = 6;
  @tracked selectedProactiveAddon = true;
  @tracked paymentMethod = '';
  @tracked selectedPlanId = PRICING_5_X_CORE_ADVANCED_ID;
  @tracked solutionId = Number(this.args.solutionId);

  INTENT_TYPES = INTENT_TYPES;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (!this.solutionId) {
      this.solutionId = this.isEarlyStageFree
        ? Number(PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID)
        : Number(PRICING_5_X_EARLY_STAGE_SOLUTION_ID);
    }
    this.minimumSeatNumber = this.appService.app.humanAdminsWithCoreSeat.length;
    taskFor(this.getQuotes).perform();
  }

  get isEarlyStageFree() {
    return (
      this.customerService.customer.earlyStageApplicationSolutionId.toString() ===
      PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID
    );
  }

  get earlyStageDiscountAmounts() {
    return {
      yearOne: percentDiscountForSolution(this.solutionId.toString(), 1),
      yearTwo: percentDiscountForSolution(this.solutionId.toString(), 2),
      yearThree: percentDiscountForSolution(this.solutionId.toString(), 3),
    };
  }

  get earlyStageProgramName() {
    let solution = findSolution(this.solutionId.toString());
    return this.intl.t(solution.marketingNameTranslationKey);
  }

  get queryParamsForRedirect() {
    return {
      solution_id: this.solutionId,
      plan_id: this.selectedPlanId,
      cardless_trial: true,
      billing_period: this.billingPeriod,
      seat_number: this.seatNumber,
      proactive_addon: this.selectedProactiveAddon,
      copilot_addon: true,
    };
  }

  get redirectUrl() {
    let baseUrl = window.location.origin;
    let url = this.router.urlFor('apps.app.teams-checkout.plan', this.appService.app.id, {
      queryParams: this.queryParamsForRedirect,
    });
    return `${baseUrl}${url}`;
  }

  get adminIdsWithCopilotSeats() {
    return this.appService.app.humanAdminsWithCopilotSeat.map((admin: any) => admin.id);
  }

  get selectedPlanIds() {
    return [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
  }

  get copilotSeatNumber() {
    //we set the number of seats to the max between current admins in app and minimum mandatory base usage if any
    return Math.max(this.includedMinimumCopilotSeats, this.minimumSeatNumber);
  }

  get loading() {
    return this.quoteService.loading;
  }

  get discountedCopilotAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(FIN_AI_COPILOT_BASE_ID)) || 0;
  }

  get seatNumber() {
    //we set the number of seats to the max between current admins in app and minimum mandatory base usage if any
    return Math.max(this.includedMinimumSeats, this.minimumSeatNumber);
  }

  get includedMinimumSeats() {
    return (
      this.quote?.metricPlanLimitMinimum(Number(this.selectedPlanId), Metric.core_seat_count) || 0
    );
  }

  get includedMinimumCopilotSeats() {
    return (
      this.quote?.metricPlanLimitMinimum(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) || 0
    );
  }

  get quote() {
    return this.earlyStageQuote;
  }

  get earlyStageQuote() {
    return this.quoteService.getFirstQuoteByPricingModelIdentifier(
      this.earlyStagePricingModelIdentifier,
    );
  }

  get quoteWithDefaultPricingModel() {
    return this.quoteService.getFirstQuoteByPricingModelIdentifier(
      PricingModelIdentifier.PRICING_5_1,
    );
  }

  get earlyStagePricingModelIdentifier() {
    return this.isEarlyStageFree
      ? PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE_FREE
      : PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE;
  }

  get fullSeatPrice() {
    return (
      this.quoteWithDefaultPricingModel?.fullSeatPrice(
        Number(this.selectedPlanId),
        Metric.core_seat_count,
      ) || 0
    );
  }

  get discountedSeatPrice() {
    return this.quote?.fullSeatPrice(Number(this.selectedPlanId)) || 0;
  }

  get discountedSeatFlatFeePrice(): number {
    return (
      this.quote?.fullSeatFlatFeePrice(Number(this.selectedPlanId), Metric.core_seat_count) || 0
    );
  }

  get fullProactiveAddonPrice() {
    return (
      this.quoteWithDefaultPricingModel?.fullBreakdownAmount(
        Number(PROACTIVE_SUPPORT_PLUS_BASE_ID),
      ) || 0
    );
  }
  get discountedProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID)) || 0;
  }

  get perSeatCopilotAddonPrice() {
    return (
      this.quote?.fullSeatPrice(Number(FIN_AI_COPILOT_BASE_ID), Metric.copilot_seat_count) || 0
    );
  }

  get fullCopilotAddonPrice() {
    let addonPrice =
      this.quoteWithDefaultPricingModel?.fullSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) || 0;
    return addonPrice * this.seatNumber;
  }

  get discountedCopilotSeatPrice() {
    return (
      this.quote?.discountedAnnualSeatPrice(
        Number(FIN_AI_COPILOT_BASE_ID),
        Metric.copilot_seat_count,
      ) || 0
    );
  }

  get expectedTrialEndDate() {
    let trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + 14);
    return trialEndDate;
  }

  get renewalDayOfMonth() {
    return moment.localeData().ordinal(this.expectedTrialEndDate.getDate());
  }

  get monthlyCopilotPriceForSeats() {
    return this.perSeatCopilotAddonPrice * this.copilotSeatNumber;
  }

  get hasCopilotEarlyStageDiscount() {
    return this.fullCopilotAddonPrice > this.discountedCopilotAddonPrice;
  }

  get monthlyDiscountedTotal() {
    let total = this.monthlyDiscountedSeatPrice + this.monthlyProactiveAddonPrice;
    if (!this.hasCopilotEarlyStageDiscount) {
      total += this.monthlyCopilotPriceForSeats;
    } else {
      total += this.monthlyCopilotAddonPriceForEarlyStage;
    }
    return total;
  }

  get earlyStagePricingArticleId() {
    return this.isEarlyStageFree
      ? EARLY_STAGE_FREE_WITH_COPILOT_PRICING_ARTICLE_ID
      : EARLY_STAGE_WITH_COPILOT_PRICING_ARTICLE_ID;
  }

  get monthlyProactiveAddonPrice() {
    return _.isNumber(this.discountedProactiveAddonPrice)
      ? this.discountedProactiveAddonPrice
      : this.fullProactiveAddonPrice;
  }

  get monthlyCopilotAddonPriceForEarlyStage() {
    return _.isNumber(this.discountedCopilotAddonPrice)
      ? this.discountedCopilotAddonPrice
      : this.fullCopilotAddonPrice;
  }

  get totalPrice() {
    return this.monthlyDiscountedTotal;
  }

  get totalPriceInCents() {
    return this.totalPrice * 100;
  }

  get totalPriceFormatted() {
    return this.intl.t('signup.teams.pricing5.annual-plans.basket.due-after-trial-price', {
      fullPrice: this.monthlyTotal,
      discountPrice: this.intl.formatNumber(this.totalPrice, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      htmlSafe: true,
    });
  }

  get monthlyFullSeatPrice() {
    return this.fullSeatPrice * this.seatNumber;
  }

  get monthlyDiscountedSeatPrice() {
    return this.discountedSeatFlatFeePrice + this.discountedSeatUsagePrice;
  }

  get additionalSeatUsage() {
    return this.seatNumber - this.includedMinimumSeats;
  }

  get discountedSeatUsagePrice() {
    return this.discountedSeatPrice * this.additionalSeatUsage;
  }

  get monthlyTotal() {
    return this.monthlyFullSeatPrice + this.fullProactiveAddonPrice + this.fullCopilotAddonPrice;
  }

  get backButtonLabel() {
    return this.intl.t('signup.teams.header.choose_your_plan');
  }

  get nonCorePlanIdsToFetch() {
    return this.appService.app.allPlansOnPricingModel
      .filter((plan: Plan) => plan.product.addon && plan.selfServe)
      .map((plan: Plan) => plan.idAsNumber);
  }

  get planIdsForQuote() {
    return [Number(this.selectedPlanId), ...this.nonCorePlanIdsToFetch];
  }

  get proactiveSupportBaseUsage() {
    return this.quote?.proactiveSupportBaseUsage;
  }

  backToOnboardingHomeAndOpenConversionModal() {
    let queryParams = { action: 'buy_intercom' };
    this.router.transitionTo(this.appService.app.onboardingHomeRoute, { queryParams });
  }

  @action
  onBackButtonClick() {
    this.backToOnboardingHomeAndOpenConversionModal();
  }

  @action showEarlyStagePricingArticle() {
    let object = this.isEarlyStageFree
      ? 'early_stage_free_pricing_article'
      : 'early_stage_pricing_article';
    window.Intercom('showArticle', this.earlyStagePricingArticleId);
    this.sendAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }

  @action showProactiveSupportProArticle() {
    window.Intercom('showArticle', this.appService.app.proactiveSupportProArticleId);
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'metered_proactive_support_plus_article',
    });
  }

  @action sendAnalyticsEvent({
    action,
    object,
    context,
  }: {
    action: string;
    object: string;
    context?: Record<string, any>;
  }) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context,
      place: 'p5-early-stage-checkout',
    });
  }

  @keepLatestTask
  *getQuotes(): TaskGenerator<void> {
    let pricingModelsNeeded = [
      PricingModelIdentifier.PRICING_5_1,
      this.earlyStagePricingModelIdentifier,
    ];

    let params = pricingModelsNeeded.map((pricingModelIdentifier) => ({
      ...DEFAULT_REQUEST_PARAMS,
      planIds: this.selectedPlanIds,
      pricingModelIdentifier,
      source: 'pricing-five-checkout',
      coreSeatCount: this.seatNumber,
    }));

    yield taskFor(this.quoteService.getQuotes).perform(params);
  }

  @keepLatestTask
  *convertSubscription(afterPaymentMethodSuccess: AfterPaymentMethodSuccess): TaskGenerator<void> {
    let { paymentMethod, address } = afterPaymentMethodSuccess;
    let paramsForAnalytics = {
      solution_id: this.solutionId,
      plan_ids: this.selectedPlanIds,
      ...(this.isEarlyStageFree && { partner: this.earlyStageService.partnerSource }),
    };

    let params = {
      ...paramsForAnalytics,
      country_code: address.countryCode,
      street_address: address.streetAddress,
      stripe_payment_method_id: paymentMethod,
      app_id: this.appService.app.id,
      ...(address.stateCode && {
        state_code: address.stateCode,
      }),
      ...(address.postCode && {
        postcode: address.postCode,
      }),
      ...(address.city && {
        city: address.city,
      }),
    };

    try {
      yield post(`/ember/customers/${this.appService.app.id}/convert_subscription`, params);
      this.sendAnalyticsEvent({
        action: 'completed',
        object: 'convert_subscription',
        context: paramsForAnalytics,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('signup.teams.pricing5.annual-plans.convert.success'),
      );
      this.router.transitionTo('apps.app.checkout.success', this.appService.app.id);
    } catch (err) {
      console.error(err);
      if (err && err?.jqXHR?.responseJSON && err?.jqXHR?.responseJSON[0]) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON[0]);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
      }
      throw err;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Checkouts::EarlyStageCheckout': typeof EarlyStageCheckout;
  }
}
