/* import __COLOCATED_TEMPLATE__ from './activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import type CustomIntlService from 'embercom/services/intl';

export interface Args {}

export default class StandaloneIntercomMessengerSetupActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: CustomIntlService;

  @action async activateFinForMessenger() {
    try {
      await this.finStandaloneService.enableMessenger();
      this.notifyConfirmation(
        this.intl.t('standalone.intercom-messenger.activation.activation-success-notification'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'standalone.intercom-messenger.activation.activation-error-notification',
        ),
      });
    }
  }

  @action async deactivateFinForMessenger() {
    try {
      await this.finStandaloneService.disableMessenger();
      this.notifyInfo(
        this.intl.t('standalone.intercom-messenger.activation.deactivation-success-notification'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'standalone.intercom-messenger.activation.deactivation-error-notification',
        ),
      });
    }
  }

  private notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }

  private notifyInfo(message: string) {
    this.notificationsService.notify(message, ENV.APP._2000MS, 'fin-standalone-activation');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup::Activation': typeof StandaloneIntercomMessengerSetupActivation;
  }
}
