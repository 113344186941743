/* import __COLOCATED_TEMPLATE__ from './basket.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  CORE_ENGAGE_ID,
  findSolution,
  PEOPLE_REACHED_ID,
  VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { union, without } from 'underscore';

export default class Basket extends Component {
  @tracked showItemizedBreakdown = false;

  @service purchaseAnalyticsService;
  @service appService;
  @service intl;
  @service router;
  @service cardlessTrialService;

  get app() {
    return this.appService.app;
  }

  get solutionId() {
    return this.args.solutionId;
  }

  get solution() {
    return findSolution(this.solutionId);
  }

  get solutionHighlightClass() {
    if (this.args.showBillingPeriodDetails) {
      return '';
    }

    let safeClassName = this.solution?.name?.toLowerCase()?.replace(/\W/g, '-');

    return `signup__basket__solution-highlight-${safeClassName || 'default'}`;
  }

  get billingPeriodDurationInMonths() {
    return this.args.billingPeriodDurationInMonths;
  }

  get price() {
    return this.args.price;
  }

  get showTotalAfterTrial() {
    if (this.args.isEarlyStage) {
      return true;
    }
    if (this.args.convertCardlessTrialSubscription || this.args.customerHasEverHadSubscription) {
      return false;
    }
    if (this.args.isBillingPeriodSelected) {
      return true;
    }
    return false;
  }

  get showFourteenDayFreeTrial() {
    if (this.cardlessTrialService.canUseEarlyStageDetection) {
      return true;
    }
    if (this.args.convertCardlessTrialSubscription || this.args.customerHasEverHadSubscription) {
      return false;
    }
    return true;
  }

  get priceBreakdowns() {
    let priceBreakdowns = this.price.getBreakdownInfo(this.args.billingPeriodDurationInMonths);
    priceBreakdowns.forEach((productBreakdown) => {
      let breakdownWithoutFin = productBreakdown.breakdown.filter(
        (metric) => metric.metricName !== Metric.resolutions,
      );
      productBreakdown.breakdown = breakdownWithoutFin;
      return productBreakdown;
    });

    // FOR VBP 2.0 Early Stage, there is an implicit People Reached tier when
    // adding the Engage Pro plan. This checks if both Engage Pro and People Reached tier 2
    // are part of the plan bundle. If so, copy the People Reached charges into the Engage Pro
    // charges and don't display the People Reached plan
    if (this.solutionId === VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID) {
      let engagePro = priceBreakdowns?.find((charge) => charge.productId === CORE_ENGAGE_ID);
      let peopleReachedTier2 = priceBreakdowns?.find(
        (charge) => charge.productId === PEOPLE_REACHED_ID,
      );
      let hideImplicitPlans = [];
      if (engagePro && peopleReachedTier2) {
        engagePro.breakdown = union(engagePro.breakdown, peopleReachedTier2.breakdown);
        hideImplicitPlans.push(peopleReachedTier2);
      }
      return without(priceBreakdowns, ...hideImplicitPlans);
    } else {
      return priceBreakdowns;
    }
  }

  get monthlyTotalAfterTrialBeforeDiscount() {
    return this.price.preDiscountPricingByBillingPeriod[this.billingPeriodDurationInMonths]
      .monthly_price_in_cents;
  }

  get monthlyTotalAfterTrial() {
    return (
      this.price.getMonthlyTotalAfterTrial(this.billingPeriodDurationInMonths) -
      (this.args.couponDiscountAmountInCents || 0)
    );
  }

  get billedAsPerBillingPeriod() {
    return this.price.getBilledAsAmount(this.billingPeriodDurationInMonths);
  }

  get isAnnualPlanSelected() {
    return this.billingPeriodDurationInMonths === 12;
  }

  get coupon() {
    let coupon = this.price.breakdown.find((b) => b.name === 'coupon');
    if (coupon) {
      let sign = coupon.amount > 0 ? '' : '− ';
      let amount = Math.abs(coupon.amount) / 100;
      coupon.price = `${sign}${this.intl.formatNumber(amount, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      })}`;
      return coupon;
    }
  }

  get dueTodayAmount() {
    if (this.args.isEarlyStage) {
      return 0;
    } else if (
      this.args.convertCardlessTrialSubscription ||
      this.args.customerHasEverHadSubscription
    ) {
      return this.isAnnualPlanSelected
        ? this.billedAsPerBillingPeriod
        : this.monthlyTotalAfterTrial;
    }
    return 0;
  }

  get products() {
    return this.priceBreakdowns.filter((breakdown) => !breakdown.addon);
  }

  get addons() {
    return this.priceBreakdowns.filter((breakdown) => breakdown.addon);
  }

  get finResolutionPrice() {
    if (this.args.earlyStagePartner) {
      return 0;
    } else if (this.args.isEarlyStage) {
      return 5;
    } else {
      return 99;
    }
  }

  @action
  toggleShowItemizedBreakdown() {
    this.showItemizedBreakdown = !this.showItemizedBreakdown;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      section: 'sign_up_flow',
      place: 'sign_up_flow',
      object: 'itemized_breakdown',
      billing_period_duration_in_months: this.billingPeriodDurationInMonths,
    });
  }
}
