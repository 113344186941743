/* import __COLOCATED_TEMPLATE__ from './messenger-language-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface MessengerLanguageChangeDetailsArgs {
  event: {
    value: {
      default_locale_before: string;
      default_locale_after: string;
      permitted_locales_before: string[];
      permitted_locales_after: string[];
    };
  };
}

export default class MessengerLanguageChangeDetails extends Component<MessengerLanguageChangeDetailsArgs> {
  @service declare intl: IntlService;

  @tracked showModal = false;

  get defaultLocaleHasChanged(): boolean {
    return (
      this.args.event.value.default_locale_before !== this.args.event.value.default_locale_after
    );
  }

  get permittedLocalesHaveChanged(): boolean {
    return (
      this.args.event.value.permitted_locales_before.sort().join() !==
      this.args.event.value.permitted_locales_after.sort().join()
    );
  }

  languageForLocale = (locale: string): string => {
    return this.intl.t(`app.lib.languages.language.${locale}`);
  };

  permittedLocaleRemoved = (locale: string): boolean => {
    return !this.args.event.value.permitted_locales_after.includes(locale);
  };

  permittedLocaleAdded = (locale: string): boolean => {
    return !this.args.event.value.permitted_locales_before.includes(locale);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::MessengerLanguageChangeDetails': typeof MessengerLanguageChangeDetails;
    'table-cells/admin-events/messenger-language-change-details': typeof MessengerLanguageChangeDetails;
  }
}
