/* import __COLOCATED_TEMPLATE__ from './ai-agent-multilingual-settings-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type NotificationsService from 'embercom/services/notifications-service';

interface AiAgentMultilingualSettingsChangeDetailsArgs {
  event: {
    value: {
      fallback_search_locale_enabled: {
        before: boolean;
        after: boolean;
      };
      fallback_search_locale: {
        before: string;
        after: string;
      };
    };
  };
}

interface Locale {
  name: string;
  localeId: string;
}

export const FALLBACK_SEARCH_LOCALE_ENABLED_SETTING_ID = 'fallback_search_locale_enabled';
export const FALLBACK_SEARCH_LOCALE_SETTING_ID = 'fallback_search_locale';

type SettingId =
  | typeof FALLBACK_SEARCH_LOCALE_SETTING_ID
  | typeof FALLBACK_SEARCH_LOCALE_ENABLED_SETTING_ID;

export default class AiAgentMultilingualSettingsChangeDetails extends Component<AiAgentMultilingualSettingsChangeDetailsArgs> {
  constructor(owner: unknown, args: AiAgentMultilingualSettingsChangeDetailsArgs) {
    super(owner, args);
    taskFor(this.fetchMessengerSettings).perform();
  }

  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @tracked messengerSettings: any | null = null;

  @tracked showModal = false;

  get settingIds(): SettingId[] {
    return [FALLBACK_SEARCH_LOCALE_ENABLED_SETTING_ID, FALLBACK_SEARCH_LOCALE_SETTING_ID];
  }

  get isLoading() {
    return taskFor(this.fetchMessengerSettings).isRunning;
  }

  lookupLocaleName(localeId: string) {
    let localeName = this.messengerSettings?.languages.supportedLocales.find(
      (locale: Locale) => locale.localeId === localeId,
    )?.name;

    return localeName ?? localeId;
  }

  get settingsWithBeforeAndAfter() {
    return this.settingIds.map((settingId) => {
      let { before, after } = this.args.event.value[settingId] as { before: string; after: string };

      if (settingId === FALLBACK_SEARCH_LOCALE_SETTING_ID) {
        if (before === 'app_locale') {
          before = this.intl.t(
            'ai-agent.settings.multilingual.real-time-translation.admin-events.default-language',
          );
        } else {
          before = this.lookupLocaleName(before);
        }

        if (after === 'app_locale') {
          after = this.intl.t(
            'ai-agent.settings.multilingual.real-time-translation.admin-events.default-language',
          );
        } else {
          after = this.lookupLocaleName(after);
        }

        return {
          id: settingId,
          name: this.intl.t(
            'ai-agent.settings.multilingual.real-time-translation.admin-events.locale-title',
          ),
          before,
          after,
          hasChanged: before !== after,
        };
      } else if (settingId === FALLBACK_SEARCH_LOCALE_ENABLED_SETTING_ID) {
        let beforeValue = `ai-agent.settings.multilingual.real-time-translation.admin-events.${before ? 'enabled' : 'disabled'}`;
        let afterValue = `ai-agent.settings.multilingual.real-time-translation.admin-events.${after ? 'enabled' : 'disabled'}`;
        return {
          id: settingId,
          name: this.intl.t(
            'ai-agent.settings.multilingual.real-time-translation.admin-events.enabled-title',
          ),
          before: this.intl.t(beforeValue),
          after: this.intl.t(afterValue),
          hasChanged: before !== after,
        };
      }

      return null;
    });
  }

  @task({ restartable: true })
  *fetchMessengerSettings(): Generator<Promise<Store>> {
    let appId = this.appService.app.id;
    try {
      this.messengerSettings = yield this.store.findRecord('messenger-settings/all', appId);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.settings.multilingual.fetch-error'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::AiAgentMultilingualSettingsChangeDetails': typeof AiAgentMultilingualSettingsChangeDetails;
    'table-cells/admin-events/ai-agent-multilingual-settings-change-details': typeof AiAgentMultilingualSettingsChangeDetails;
  }
}
