/* import __COLOCATED_TEMPLATE__ from './navbar.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import { NavItem, type NavItemInputs, NavTree } from 'embercom/objects/standalone/nav';

interface Args {}

export default class StandaloneNavbar extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  @tracked links: NavItem[] = [];

  settingsLink: NavItem;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let tree = new NavTree(owner);

    let navItems = [
      {
        route: 'apps.app.standalone.channels',
        icon: 'transfer',
        label: 'Channels',
        // eslint-disable-next-line object-shorthand
        customOnClick: function () {
          let self = this as NavItem;
          self.root.setForcedActiveItem(self);
          self.router.transitionTo(`${self.route}.overview`);
        },
        children: [
          {
            route: 'apps.app.standalone.channels.zendesk.tickets',
            label: 'Zendesk tickets',
            excludedFeatures: [
              'team-standalone-fin-standalone-salesforce',
              'team-standalone-intercom-messenger',
            ],
          },
          {
            route: 'apps.app.standalone.channels.zendesk.messaging',
            label: 'Zendesk Messaging',
            excludedFeatures: [
              'team-standalone-fin-standalone-salesforce',
              'team-standalone-intercom-messenger',
            ],
          },
        ],
      },
      {
        route: 'apps.app.knowledge-hub.overview',
        icon: 'campaign',
        label: 'Knowledge',
        children: [
          {
            route: 'apps.app.knowledge-hub.overview',
            label: 'Sources',
          },
          {
            route: 'apps.app.knowledge-hub.all-content',
            label: 'Content',
            activeRoutes: [
              'apps.app.knowledge-hub.all-content',
              'apps.app.knowledge-hub.edit',
              'apps.app.knowledge-hub.folder',
              'apps.app.knowledge-hub.loading',
              'apps.app.knowledge-hub.new',
              'apps.app.knowledge-hub.view',
            ],
          },
          {
            route: 'apps.app.standalone.knowledge.custom-answers',
            label: 'Custom Answers',
          },
          {
            route: 'apps.app.standalone.knowledge.audiences',
            label: 'Audiences',
          },
        ],
      },
      {
        route: 'apps.app.standalone.personality',
        icon: 'lwr-happy',
        label: 'Personality',
      },
      {
        route: 'apps.app.standalone.guidance',
        icon: 'survey-filled',
        label: 'Guidance',
        requiredFeatures: ['fin-guidance-closed-beta', 'team-standalone-fin-guidance'],
      },
      {
        route: 'apps.app.standalone.testing',
        icon: 'test',
        label: 'Testing',
        requiredFeatures: ['team-standalone-fin-playground', 'answerbot-fin-playground'],
      },
      {
        route: 'apps.app.standalone.reports',
        icon: 'bar-charts',
        label: 'Reports',
        children: [
          {
            route: 'apps.app.standalone.reports',
            label: 'Performance',
          },
          {
            route: 'apps.app.standalone.content-gaps',
            label: 'Content gaps',
          },
        ],
      },
    ] as NavItemInputs[];

    this.links = navItems.map((item) => new NavItem(tree, owner, item));

    this.settingsLink = new NavItem(tree, owner, {
      route: 'apps.app.settings',
      icon: 'settings',
      label: 'Settings',
      children: [
        {
          route: 'apps.app.settings.workspace.general',
          label: 'General',
        },
        {
          route: 'apps.app.settings.workspace.teammates',
          label: 'Teammates',
        },
        {
          route: 'apps.app.settings.ai-automation.fin-ai-agent',
          label: 'Fin AI Agent',
        },
        {
          route: 'apps.app.settings.standalone.data',
          label: 'Data',
        },
        {
          route: 'apps.app.settings.workspace.office-hours',
          label: 'Office hours',
        },
        {
          route: 'apps.app.billing',
          label: 'Billing',
        },
      ],
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar': typeof StandaloneNavbar;
    'standalone::navbar': typeof StandaloneNavbar;
  }
}
