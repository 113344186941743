/* import __COLOCATED_TEMPLATE__ from './payment-method.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type { TaskGenerator } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import {
  CARD_BRAND_ICONS,
  CARD_BRAND_NAMES,
  DEFAULT_CARD_ICON,
} from 'embercom/lib/payment-methods';

interface Args {
  isMountingElements: boolean;
  mountStripeElements: ({ forceReload }: { forceReload: boolean }) => TaskGenerator<void>;
  setVatNumber?: (vatNumber: Event) => void;
  showVatField?: boolean;
  countryCode?: string;
  supportExistingPaymentMethod: boolean;
  useExistingCard: boolean;
  setUseExistingCard: (value: boolean) => void;
  hasCard: boolean;
  formattedCardExpiryMonth: string;
  cardHasExpired: boolean;
  existingCard: {
    cardBrand: string;
    cardLastFour: string;
    cardExpiryMonth: string;
    cardExpiryYear: string;
  };
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class PaymentMethod extends Component<Signature> {
  @service declare customerService: any;
  @service declare intl: IntlService;
  get cardBrandIcon() {
    return CARD_BRAND_ICONS[this.args.existingCard.cardBrand] || DEFAULT_CARD_ICON;
  }

  get displayableCardBrand() {
    return (
      CARD_BRAND_NAMES[this.args.existingCard.cardBrand] ||
      this.intl.t('billing.stripe-component.credit-card')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::PaymentMethod': typeof PaymentMethod;
    'stripe/payment-method': typeof PaymentMethod;
  }
}
