/* import __COLOCATED_TEMPLATE__ from './header-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
} from 'embercom/lib/billing';
import { SUPPORTED_LANGUAGES } from 'embercom/services/intl';

export default class HeaderComponent extends Component {
  @service media;
  @service appService;
  @service intl;
  @service cardlessTrialService;
  @service router;

  get standardStepNames() {
    return [
      this.intl.t('signup.teams.step_header.create_account_step_header'),
      this.build_trial_step_header,
      this.intl.t('signup.teams.step_header.start_trial_step_header'),
    ];
  }

  get earlyStageStepNames() {
    return [
      this.intl.t('signup.teams.step_header.create_account_step_header'),
      this.intl.t('signup.teams.step_header.apply_for_early_stage_step_header'),
      this.intl.t('signup.teams.step_header.start_trial_step_header'),
    ];
  }

  get startupStepNames() {
    return [
      this.intl.t('signup.teams.step_header.create_account_step_header'),
      this.intl.t('signup.teams.step_header.apply_for_startup_program'),
      this.intl.t('signup.teams.step_header.start_trial_step_header'),
    ];
  }

  get debugModeSteps() {
    return [
      this.intl.t('signup.teams.step_header.create_account_step_header'),
      this.build_trial_step_header,
      this.intl.t('signup.teams.step_header.set_your_usage_step_header'),
      this.intl.t('signup.teams.step_header.verify_step_header'),
      this.intl.t('signup.teams.step_header.start_trial_step_header'),
      this.intl.t('signup.teams.step_header.apply_for_early_stage_step_header'),
      this.intl.t('signup.teams.step_header.invite_teammates'),
    ];
  }

  get build_trial_step_header() {
    if (this.cardlessTrialService.isInCardlessTrial) {
      return this.intl.t('signup.teams.step_header.build_plan_step_header');
    }
    return this.intl.t('signup.teams.step_header.build_trial_step_header');
  }

  get app() {
    return this.appService.app;
  }

  get debugMode() {
    return this.app?.debuggingPurchaseFlow;
  }

  get stepNames() {
    if (this.debugMode) {
      return this.debugModeSteps;
    }
    if (this.args.earlyStagePartner) {
      return this.startupStepNames;
    }
    if (this.args.isEarlyStage) {
      return this.earlyStageStepNames;
    }
    return this.standardStepNames;
  }

  get currentStepName() {
    return this.stepNames[this.args.navStep - 1];
  }

  get steps() {
    return this.stepNames.map((stepName, i) => {
      let navStep = i + 1;

      return {
        text: this.debugMode ? stepName : `${navStep}. ${stepName}`,
        class: navStep === this.args.navStep && !this.debugMode ? 'o__current' : '',
        route: this.getRouteFromStepName(stepName),
      };
    });
  }

  get alreadySignedIn() {
    return this.args.authenticatedAdmin;
  }

  get price() {
    return this.args.price;
  }

  get billingPeriodDurationInMonths() {
    return this.args.billingPeriodDurationInMonths;
  }

  get isAnnualPlanSelected() {
    return this.billingPeriodDurationInMonths === 12;
  }

  get monthlyTotalAfterTrial() {
    if (!this.price) {
      return 0;
    }
    return (
      this.price.getMonthlyTotalAfterTrial(this.billingPeriodDurationInMonths) -
      (this.args.couponDiscountAmountInCents || 0)
    );
  }

  get billedAsPerBillingPeriod() {
    if (!this.price) {
      return 0;
    }
    return this.price.getBilledAsAmount(this.billingPeriodDurationInMonths);
  }

  get shouldRoundUp() {
    return true;
  }

  get isPrecisionZero() {
    return true;
  }

  get selectedLocale() {
    return this.intl.primaryLocale;
  }

  get locale() {
    return this.localeList.filter(
      (item) => item.value.toUpperCase() === this.selectedLocale.toUpperCase(),
    )[0];
  }

  get localeList() {
    return SUPPORTED_LANGUAGES.map(({ locale: value, language: text }) => {
      return {
        value,
        text,
        onSelectItem: () => {
          this.args.changeLocale(value);
        },
      };
    });
  }

  get showIntercomStarterTitle() {
    return this.cardlessTrialService.isInCardlessTrial || this.args.customerHasEverHadSubscription;
  }

  get showStepsIndicator() {
    return this.stepNames.length > 1 && this.args.navStep && !this.args.shouldShowPricing5Checkout;
  }

  getRouteFromStepName(stepName) {
    if (!this.app || !this.debugMode) {
      return;
    } else if (stepName === this.build_trial_step_header) {
      return 'apps.app.teams-checkout.plan';
    } else if (stepName === this.intl.t('signup.teams.step_header.set_your_usage_step_header')) {
      return 'apps.app.teams-checkout.usage';
    } else if (stepName === this.intl.t('signup.teams.step_header.verify_step_header')) {
      return 'apps.app.teams-checkout.verify';
    } else if (stepName === this.intl.t('signup.teams.step_header.start_trial_step_header')) {
      return 'apps.app.teams-checkout.confirm';
    } else if (
      stepName === this.intl.t('signup.teams.step_header.apply_for_early_stage_step_header') ||
      stepName === this.intl.t('signup.teams.step_header.apply_for_startup_program')
    ) {
      return 'apps.app.teams-checkout.early-stage-application';
    } else if (stepName === this.intl.t('signup.teams.step_header.invite_teammates')) {
      return 'apps.app.teams-checkout.invite-teammates';
    }
  }

  get backButtonLabel() {
    if (this.showBackButtonForEarlyStageDetection) {
      return this.intl.t('signup.teams.header.back');
    }

    if (this.isOnCheckoutConfirmPage) {
      return this.intl.t('signup.teams.header.build_your_plan');
    }

    return this.intl.t('signup.teams.header.choose_your_plan');
  }

  get isOnCheckoutConfirmPage() {
    return this.router.currentRouteName === 'apps.app.teams-checkout.confirm';
  }

  get isOnEarlyStageApplicationPage() {
    return this.router.currentRouteName === 'apps.app.teams-checkout.early-stage-application';
  }

  get isPricing5EarlyStage() {
    return PRICING_5_X_EARLY_STAGE_SOLUTION_IDS.includes(this.args.solutionId);
  }

  get isPricing5EarlyStageFree() {
    return this.args.solutionId === PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID;
  }

  get onPricing5EarlyStageApplication() {
    return (
      this.isPricing5EarlyStage &&
      (this.isOnEarlyStageApplicationPage || this.isOnCheckoutConfirmPage)
    );
  }

  get isPricing5EarlyStageSolutionId() {
    return this.args.solutionId === PRICING_5_X_EARLY_STAGE_SOLUTION_ID;
  }

  get showBackButtonForEarlyStageDetection() {
    return (
      this.cardlessTrialService.canUseEarlyStageDetection &&
      this.isPricing5EarlyStageSolutionId &&
      (this.isOnEarlyStageApplicationPage || this.isOnCheckoutConfirmPage)
    );
  }

  backToPreviousPage() {
    window.history.back();
  }

  backToOnboardingHomeAndOpenConversionModal() {
    let queryParams = { action: 'buy_intercom' };

    this.router.transitionTo(this.app.onboardingHomeRoute, { queryParams });
  }

  @action backToOnboardingHome() {
    this.router.transitionTo(this.app.onboardingHomeRoute, { queryParams: {} });
  }

  @action
  onBackButtonClick() {
    if (this.isOnCheckoutConfirmPage || this.showBackButtonForEarlyStageDetection) {
      this.backToPreviousPage();
    } else {
      this.backToOnboardingHomeAndOpenConversionModal();
    }
  }
}
