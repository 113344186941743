/* import __COLOCATED_TEMPLATE__ from './api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceApiConnection extends Component<Args> {
  @service declare appService: any;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: $TSFixMe;
  @service declare redirectService: any;

  get installGroupList() {
    return [
      {
        items: [
          {
            text: 'Connect via sandbox',
            icon: 'code',
            description: 'For testing Fin before you go live',
            onSelectItem: () => this.startConnectFlow(true),
          },
          {
            text: 'Connect to production',
            icon: 'salesforce',
            description: "For when you're ready to set Fin live",
            onSelectItem: () => this.startConnectFlow(false),
          },
        ],
      },
    ];
  }

  @action startOauthFlow() {
    let uri = this.oauthUrls.verify;
    let params = `app_id_code=${this.salesforceConfig.id}&client_id=${this.salesforceConfig.clientId}`;
    window.location.href = `${uri}?${params}`;
  }

  @action startConnectFlow(sandbox = false) {
    let uri = this.oauthUrls.connect;
    let params = `app_id_code=${this.appService.app.id}&sandbox=${sandbox}`;
    this.redirectService.redirect(`${uri}?${params}`);
  }

  @action async disconnectApp() {
    let confirmationConfig: {};
    if (this.appService.app.canUseStandaloneSalesforceConnectFlow) {
      confirmationConfig = {
        body: 'Are you sure you want to disconnect from Salesforce? Fin will immediately stop responding to cases.',
        confirmButtonText: 'Disconnect',
        primaryButtonType: 'primary-destructive',
      };
    } else {
      confirmationConfig = {
        body: 'Are you sure you want to delete your Salesforce configuration? If you have connected to Salesforce, Fin will immediately stop responding to cases.',
        confirmButtonText: 'Delete Salesforce Configuration',
      };
    }

    let isConfirmed = await this.intercomConfirmService.confirm(confirmationConfig);

    try {
      if (isConfirmed) {
        await this.salesforceConfig.disconnectApp();
        this.notificationsService.notifyConfirmation(
          this.appService.app.canUseStandaloneSalesforceConnectFlow
            ? 'You have disconnected from Salesforce.'
            : 'Your Salesforce configuration has been deleted.',
        );
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't disconnect your Salesforce app. Please try again`,
      });
    }
  }

  get oauthUrls() {
    let { protocol, host } = window.location;
    return {
      verify: `${protocol}//${host}/standalone/salesforce/verify`,
      callback: `${protocol}//${host}/standalone/salesforce/callback`,
      connect: `${protocol}//${host}/standalone/salesforce/connect`,
      connectCallback: `${protocol}//${host}/standalone/salesforce/connect_callback`,
    };
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::ApiConnection': typeof StandaloneSalesforceApiConnection;
    'standalone/salesforce/setup/api-connection': typeof StandaloneSalesforceApiConnection;
  }
}
