/* import __COLOCATED_TEMPLATE__ from './workflow-link.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';

export interface StandaloneWorkflowLinkArgs {
  workflowId: string | number;
  route: string;
  title: string;
  isLoading?: boolean;
}

let StandaloneWorkflowLink = templateOnly<StandaloneWorkflowLinkArgs>();

export default StandaloneWorkflowLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::WorkflowLink': typeof StandaloneWorkflowLink;
  }
}
