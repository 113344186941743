/* import __COLOCATED_TEMPLATE__ from './submenu-sections.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { not, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
export default Component.extend({
  greatGuidanceService: service(),
  commonPrimaryNavBarService: service(),
  showFloatingWidget: not('greatGuidanceService.hideFloatingWidget'),
  isProductIADisabled: not('commonPrimaryNavBarService.isProductIAEnabled'),
  showFloatingWidgetShadow: and('showFloatingWidget', 'isProductIADisabled'),

  classNames: [
    'submenu__sections',
    'flex-auto',
    'flex',
    'flex-col',
    'overflow-y-auto',
    'overflow-x-hidden',
  ],
  classNameBindings: [
    'showFloatingWidget:submenu__sections__floating-widget',
    'showFloatingWidgetShadow:submenu__sections__floating-widget-shadow',
  ],
});
