/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type Model from '@ember-data/model';
import type IntlService from 'ember-intl/services/intl';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

export interface Args {
  operatorIdentity: Model;
  messengerSettings: Model & { hasUnsavedChanges: boolean };
}

export default class StandaloneIntercomMessengerSetup extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intl: IntlService;
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare notificationsService: $TSFixMe;
  @tracked selectedAccordion = '';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.messengerSettingsService.preview.selectedScreen = 'conversation';
    this.messengerSettingsService.navigation.selectedTab = 'fin';
  }

  @action onAccordionChange(accordion: string) {
    this.selectedAccordion = accordion;
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get workflowRulesetId() {
    return this.intercomMessengerConfig?.workflowRulesetId;
  }

  get isSaving(): boolean {
    return taskFor(this.saveSettings).isRunning;
  }

  @task({ drop: true })
  *saveSettings(): TaskGenerator<void> {
    try {
      yield this.args.messengerSettings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.header.success-notification'),
      );
    } catch (error) {
      if (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('messenger.settings-layout.header.failure-notification'),
        });
      }
      throw error;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::IntercomMessenger::Setup': typeof StandaloneIntercomMessengerSetup;
    'standalone/intercom-messenger/setup': typeof StandaloneIntercomMessengerSetup;
  }
}
