/* import __COLOCATED_TEMPLATE__ from './modal-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {
  attributeId: string | undefined;
  onClose: () => void;
}

export default class ModalWrapper extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get ticketField() {
    return this.finStandaloneService.standaloneConversationField(this.args.attributeId)!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataField::ModalWrapper': typeof ModalWrapper;
    'standalone/data-field/modal-wrapper': typeof ModalWrapper;
  }
}
